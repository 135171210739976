import React from "react";

const Validation = ({ isError }) => {
  return (
    <div>
      <div>
        {isError && <p className="text-danger">This is a required field.</p>}
      </div>
    </div>
  );
};

export default Validation;

import React from 'react';

import Page from 'src/components/Page';
import StickersPage from 'src/views/component/auth/Stickers';

const StickersView = () => {
  return (
    <Page title='Sign In'>
      <StickersPage />
    </Page>
  );
};

export default StickersView;

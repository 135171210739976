import React, { Fragment, useEffect, useState } from "react";
// import sticker1 from "src/assets/images/stickers/sticker(1).png";
// import sticker2 from "src/assets/images/stickers/sticker(2).png";
// import sticker3 from "src/assets/images/stickers/sticker(3).png";
// import sticker4 from "src/assets/images/stickers/sticker(4).png";
// import sticker5 from "src/assets/images/stickers/sticker(5).png";
// import sticker6 from "src/assets/images/stickers/sticker(6).png";
// import sticker7 from "src/assets/images/stickers/sticker(7).png";
// import sticker8 from "src/assets/images/stickers/sticker(8).png";
import products from "src/assets/images/products/s10.jpeg";
import user from "src/assets/images/profile/user-1.jpg";
import { useLocation } from "react-router-dom";
import LeftSideBar from "../../UI/leftsideBar";
import axiosInstance from "src/helpers/axios/axiosInstance";
import PreLoader from "src/components/Loader";
import { URLS } from "src/helpers/constants/urls";
import { useNavigate } from "react-router-dom";
import { setApiMessage } from "src/helpers/commonFunctions";
import { Config, clearStorage } from "src/helpers/context";
import Validation from "src/components/Validation";
import ReactSelect from "react-select";

const EditProfilePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isError, setIsError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [stickerList, setStickerList] = useState([]);
  const [stickerCount, setStickerCount] = useState(0);
  const [selectedSticker, setselectedSticker] = useState(0);
  const [stickerArray, setStickerArray] = useState([]);
  const [stickers, setStickers] = useState(null);

  const [editProfile, setEditProfile] = useState({
    _id: "",
    UserName: "",
    Profile_Pic: "",
    Bg_Image: "",
    IsLive: true,
    IsActive: true,
    Status: 1,
    Age: "",
    G_Img: [],
    Story_Img: [],
    Video_Call: [],
    Video_Live_Strem: [],
    Bio: "",
    HasTag: "",
    Stickerts: [],
  });

  console.log(editProfile);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "IsLive") {
      setEditProfile({ ...editProfile, [name]: checked });
    } else if (name === "IsActive") {
      setEditProfile({ ...editProfile, [name]: checked });
    } else {
      setEditProfile({ ...editProfile, [name]: value });
    }
  };

  console.log(location);

  const handleStickerAdd = () => {
    if (selectedSticker != "-1") {
      // let existStciker = editProfile?.Stickerts.findIndex(S => S.img === selectedSticker)
      // if(existStciker && existStciker != -1){
      //   let dupSticker = editProfile?.Stickerts
      //   dupSticker[existStciker].count = parseInt(stickerCount)
      //   setEditProfile({
      //     ...editProfile,
      //     Stickerts: dupSticker,
      //   });
      // }else{
      //   setEditProfile({
      //     ...editProfile,
      //     Stickerts: [
      //       ...editProfile.Stickerts,
      //       { img: selectedSticker, count: parseInt(stickerCount) },
      //     ],
      //   });
      // }
      setEditProfile({
        ...editProfile,
        Stickerts: [
          ...editProfile.Stickerts,
          { img: `/` + stickers.image, count: parseInt(stickerCount) },
        ],
      });
      setStickers(null)
    }
  };

  const handleDeleteGalleryImage = (item, i) => {
    let filtered = editProfile.G_Img.filter((v, idx) => {
      const data = v != item;
      return data;
    });
    setEditProfile({ ...editProfile, G_Img: filtered });
  };

  const handleDeleteStoryImage = (item) => {
    let filtered = editProfile.Story_Img.filter((v) => v != item);
    setEditProfile({ ...editProfile, Story_Img: filtered });
  };

  const handleDeleteVideocaoll = (item) => {
    let filtered = editProfile.Video_Call.filter((v) => v != item);
    setEditProfile({ ...editProfile, Video_Call: filtered });
  };

  const handleDeleteLiveStream = (item) => {
    let filtered = editProfile.Video_Live_Strem.filter((v) => v != item);
    setEditProfile({ ...editProfile, Video_Live_Strem: filtered });
  };

  const handleEdit = async () => {
    let sendData = {
      _id: editProfile?._id,
      UserName: editProfile?.UserName,
      Profile_Pic: editProfile?.Profile_Pic,
      Bg_Image: editProfile?.Bg_Image,
      IsLive: editProfile?.IsLive,
      Status: editProfile?.Status, // Live : 2 , Online :1 Offline : 0
      IsActive: editProfile?.IsActive,
      Age: editProfile?.Age,
      G_Img: editProfile?.G_Img,
      Story_Img: editProfile?.Story_Img,
      Video_Call: editProfile?.Video_Call,
      Video_Live_Strem: editProfile?.Video_Live_Strem,
      Bio: editProfile?.Bio,
      HasTag: editProfile?.HasTag,
      Stickerts: editProfile?.Stickerts,
    };
    console.log(sendData);
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.put(
        URLS.EDIT_FAKE_PROFILE,
        sendData
      );
      console.log(data);
      if (status === 200) {
        if (!data?.flags) {
          navigate("/admin/profile");
          // getProfileList()
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }

        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleAdd = async () => {
    let sendData = {
      UserName: editProfile?.UserName,
      Profile_Pic: editProfile?.Profile_Pic,
      Status: editProfile?.Status, // Live : 2 , Online :1 Offline : 0
      IsActive: editProfile?.IsActive,
      Bg_Image: editProfile?.Bg_Image,
      IsLive: editProfile?.IsLive,
      Age: editProfile?.Age,
      G_Img: editProfile?.G_Img,
      Story_Img: editProfile?.Story_Img,
      Video_Call: editProfile?.Video_Call,
      Video_Live_Strem: editProfile?.Video_Live_Strem,
      Bio: editProfile?.Bio,
      HasTag: editProfile?.HasTag,
      Stickerts: editProfile?.Stickerts,
      // _id: "",
    };
    console.log(sendData);
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.post(
        URLS.ADDF_FAKE_PROFILE,
        sendData
      );
      console.log("data", data);
      if (status === 200) {
        if (!data?.flags) {
          navigate("/admin/profile");
          // getProfileList()
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
          setApiMessage("error", data?.errors?.[0]?.msg);
        }

        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleAddBGImage = async (file) => {
    try {
      setLoader(true);
      let formData = new FormData();
      formData.append("BGImage", file);
      const { status, data } = await axiosInstance.post(
        URLS.UPDATE_BG_IMAGE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          // getProfileList()
          //   setApiMessage('success', data?.message);
          setEditProfile({ ...editProfile, Bg_Image: data?.path });
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleAddProfile = async (file) => {
    try {
      setLoader(true);
      let formData = new FormData();
      formData.append("profile", file);
      const { status, data } = await axiosInstance.post(
        URLS.UPDATE_PROFILE_IMAGE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          // getProfileList()
          //   setApiMessage('success', data?.message);
          setEditProfile({ ...editProfile, Profile_Pic: data?.path });
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleGallery = async (files) => {
    try {
      setLoader(true);
      let formData = new FormData();
      // let image;
      for (let file of files) {
        formData.append("gallery", file);
      }
      // formData.append("gallery", files);
      const { status, data } = await axiosInstance.post(
        URLS.GALLERY_IMAGE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          for (let i = 0; i < data?.data.length; i++) {
            const element = data?.data[i];
            editProfile.G_Img.push(element);
          }
          // editProfile?.G_Img.push(data.data);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleStory = async (files) => {
    try {
      setLoader(true);
      let formData = new FormData();
      for (let file of files) {
        formData.append("story", file);
      }
      const { status, data } = await axiosInstance.post(
        URLS.STORY_IMAGE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          // getProfileList()
          //   setApiMessage('success', data?.message);

          for (let i = 0; i < data?.data.length; i++) {
            const element = data?.data[i];
            editProfile.Story_Img.push(element);
          }
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleVideoCall = async (files) => {
    try {
      setLoader(true);
      let formData = new FormData();
      for (let file of files) {
        formData.append("VideoCall", file);
      }
      const { status, data } = await axiosInstance.post(
        URLS.VIDEO_FOR_VIDEO_CALL,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          // getProfileList()
          //   setApiMessage('success', data?.message);
          for (let i = 0; i < data?.data.length; i++) {
            const element = data?.data[i];
            editProfile.Video_Call.push(element);
          }
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleVideoLiveStream = async (files) => {
    try {
      setLoader(true);
      let formData = new FormData();
      for (let file of files) {
        formData.append("VidioStrem", file);
      }
      const { status, data } = await axiosInstance.post(
        URLS.VIDEO_FOR_LIVE_STREAM,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          // getProfileList()
          //   setApiMessage('success', data?.message);

          for (let i = 0; i < data?.data.length; i++) {
            const element = data?.data[i];
            editProfile.Video_Live_Strem.push(element);
          }
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (location?.state?.item) {
      setEditProfile(location?.state?.item);
    }
  }, [location]);

  const getSticker = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(URLS.GET_STICKERT);

      if (status === 200) {
        if (!data?.flags) {
          const response = data.data.map((item) => {
            item = { label: item.image, value: item.image, image: item.image, _id: item._id }
            return item
          })
          console.log(response);
          setStickerList(data?.data);
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getSticker();
  }, []);

  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />

        <div className="body-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-sm-12"
                style={loader ? { opacity: "0.3" } : {}}
              >
                <div className="card card-body">
                  <div className="row">
                    <div className="col-lg-6 d-flex align-items-stretch">
                      <div className="card w-100 position-relative overflow-hidden">
                        <div className="card-body p-4">
                          <div className="text-center">
                            <img
                              src={
                                editProfile && editProfile?.Bg_Image
                                  ? Config.baseURL + "/" + editProfile?.Bg_Image
                                  : "products"
                              }
                              alt=""
                              className="rounded"
                              width="130"
                              height="80"
                            />

                            {/* delete icon button */}
                            {/* <button className="btn btn-sm btn-primary" name="Profile_Pic" value={""} onClick={(e)=>handleChange(e)}>x</button> */}

                            <div className="d-flex align-items-center justify-content-center my-3">
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) =>
                                  handleAddBGImage(e.target.files[0])
                                }
                                id="upload-feature"
                              />
                              <label
                                className="btn btn-primary"
                                for="upload-feature"
                              >
                                Upload Featured
                              </label>
                            </div>
                          </div>
                          <form>
                            <div className="mb-4">
                              <label
                                for="profile-name"
                                className="form-label fw-semibold"
                              >
                                Profile Image & Name
                              </label>
                              <div className="d-flex">
                                <img
                                  src={
                                    editProfile && editProfile?.Profile_Pic
                                      ? Config.baseURL +
                                      "/" +
                                      editProfile?.Profile_Pic
                                      : products
                                  }
                                  className="me-3 user-profile-img rounded-circle"
                                  width="35"
                                  height="35"
                                  alt=""
                                />
                                <input
                                  type="name"
                                  className="form-control"
                                  id="profile-name"
                                  name="UserName"
                                  value={editProfile?.UserName}
                                  // onChange={(e) => handleChange(e)}

                                  onChange={(e) => {
                                    handleChange(e);
                                    setIsError(false);
                                  }}
                                />
                              </div>
                              {editProfile.UserName == "" && (
                                <Validation isError={isError} />
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-stretch">
                      <div className="card w-100 position-relative overflow-hidden">
                        <div className="card-body p-4">
                          {/* <form> */}
                          <div className="form-check form-switch mb-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckIsActive"
                              name="IsActive"
                              checked={editProfile?.IsActive}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckIsActive"
                            >
                              Visibility of Profile
                            </label>
                          </div>
                          <div className="mb-4 ">
                            <div className="form-check form-switch mb-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckIsLive"
                                name="IsLive"
                                checked={editProfile?.IsLive}
                                onChange={(e) => handleChange(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckIsLive"
                              >
                                Live
                              </label>
                            </div>

                            <div className="btn-group" data-bs-toggle="buttons">
                              <label className="btn btn-success text-white font-medium">
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    id="online-profile"
                                    name="Status"
                                    className="form-check-input"
                                    checked={
                                      editProfile?.Status?.toString() === "1"
                                    }
                                    value="1"
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="online-profile"
                                  >
                                    <span className="d-block d-md-none">1</span>
                                    <span className="d-none d-md-block">
                                      Online
                                    </span>
                                  </label>
                                </div>
                              </label>
                              <label className="btn btn-danger text-white font-medium">
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    id="offline-profile"
                                    name="Status"
                                    className="form-check-input"
                                    checked={
                                      editProfile?.Status?.toString() === "0"
                                    }
                                    value="0"
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="offline-profile"
                                  >
                                    <span className="d-block d-md-none">3</span>
                                    <span className="d-none d-md-block">
                                      Offline
                                    </span>
                                  </label>
                                </div>
                              </label>
                            </div>
                          </div>
                          <div className="mb-4">
                            <label
                              for="profile-age"
                              className="form-label fw-semibold"
                            >
                              Age
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="profile-age"
                              placeholder="26"
                              name="Age"
                              value={editProfile?.Age}
                              // onChange={(e) => handleChange(e)}
                              onChange={(e) => {
                                handleChange(e);
                                setIsError(false);
                              }}
                            />
                            {editProfile.Age == "" && (
                              <Validation isError={isError} />
                            )}
                          </div>
                          <div className="mb-4">
                            <label
                              for="profile-id"
                              className="form-label fw-semibold"
                            >
                              ID
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="profile-id"
                              placeholder="8456475"
                              name="Id"
                              value={editProfile?.Id}
                              onChange={(e) => handleChange(e)}
                              readOnly
                            />
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body p-4">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="card-title m-0"> Gallery Images</h5>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-primary d-flex align-items-center px-3"
                                  id="add-notes"
                                >
                                  <i className="ti ti-photo-plus me-0 me-md-1 fs-4"></i>
                                  <span className="d-none d-md-block font-weight-medium fs-3">
                                    <input
                                      type="file"
                                      multiple
                                      hidden
                                      accept="image/*"
                                      onChange={(e) => {
                                        handleGallery(e.target.files);
                                        setIsError(false);
                                      }}
                                      id="upload-gallery"
                                    />

                                    <label
                                      for="upload-gallery"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Add Images
                                    </label>
                                  </span>
                                </a>
                              </div>
                              {editProfile.G_Img.length == 0 && (
                                <Validation isError={isError} />
                              )}
                              <div className="row gx-3 mt-4">
                                {editProfile?.G_Img.length !== 0 &&
                                  editProfile?.G_Img?.map((item, i) => {
                                    return (
                                      <div className="col-3 position-relative p-2">
                                        <i
                                          className="fa-solid fa-trash-can fs-6 text-danger position-absolute top-50 start-50 translate-middle"
                                          style={{ cursor: "pointer", paddingRight: "20px" }}
                                          onClick={() =>
                                            handleDeleteGalleryImage(item, i)
                                          }
                                        ></i>
                                        {/* <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="alert"
                                          aria-label="Close"
                                          style={{ cursor: "pointer" }}
                                          // onClick={() => handleDelete(sticker?._id)}
                                        ></button> */}
                                        <img
                                          width="80"
                                          height="100"
                                          src={
                                            item
                                              ? Config.baseURL + "/" + item
                                              : products
                                          }
                                          className="rounded"
                                          alt="art"
                                        />
                                      </div>
                                    );
                                  })}
                                {/* <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body p-4">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="card-title m-0">Story Images</h5>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-primary d-flex align-items-center px-3"
                                  id="add-notes"
                                >
                                  <i className="ti ti-photo-plus me-0 me-md-1 fs-4"></i>
                                  <span className="d-none d-md-block font-weight-medium fs-3">
                                    <input
                                      type="file"
                                      multiple
                                      hidden
                                      accept="image/*"
                                      onChange={(e) => {
                                        handleStory(e.target.files);
                                        setIsError(false);
                                      }}
                                      id="upload-story"
                                    />

                                    <label
                                      for="upload-story"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Add Images
                                    </label>
                                  </span>
                                </a>
                              </div>
                              {editProfile.Story_Img.length == 0 && (
                                <Validation isError={isError} />
                              )}
                              <div className="row gx-3 mt-4">
                                {editProfile?.Story_Img?.length !== 0 &&
                                  editProfile?.Story_Img?.map((item, i) => {
                                    return (
                                      <div className="col-3 position-relative p-2">
                                        <i
                                          className="fa-solid fa-trash-can fs-6 text-danger position-absolute top-50 start-50 translate-middle"
                                          style={{ cursor: "pointer", paddingRight: "20px" }}
                                          onClick={() =>
                                            handleDeleteStoryImage(item, i)
                                          }
                                        ></i>
                                        {/* <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="alert"
                                          aria-label="Close"
                                          style={{ cursor: "pointer" }}
                                          // onClick={() => handleDelete(sticker?._id)}
                                        ></button> */}
                                        <img
                                          width="80"
                                          height="100"
                                          src={
                                            item
                                              ? Config.baseURL + "/" + item
                                              : products
                                          }
                                          className="rounded"
                                          alt="art"
                                        />
                                      </div>
                                    );
                                  })}
                                {/* <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body p-4">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="card-title m-0">
                                  Video for Video Call
                                </h5>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-primary d-flex align-items-center px-3"
                                  id="add-notes"
                                >
                                  <i className="ti ti-video-plus me-0 me-md-1 fs-4"></i>
                                  <span className="d-none d-md-block font-weight-medium fs-3">
                                    <input
                                      type="file"
                                      multiple
                                      hidden
                                      accept="video/*"
                                      onChange={(e) => {
                                        handleVideoCall(e.target.files);
                                        setIsError(false);
                                      }}
                                      id="upload-video"
                                    />

                                    <label
                                      for="upload-video"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Add Videos
                                    </label>
                                  </span>
                                </a>
                              </div>
                              {/* {editProfile.Video_Call.length == 0 && (
                                <Validation isError={isError} />
                              )} */}

                              <div className="row gx-3 mt-4">
                                {editProfile?.Video_Call?.length !== 0 &&
                                  editProfile?.Video_Call?.map((item, i) => {
                                    return (
                                      <div className="col-3 position-relative p-2">
                                        {/* <i
                                          className="fa-solid fa-trash-can fs-6 text-danger position-absolute top-0 start-50 translate-middle"
                                          style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                          }}
                                          onClick={() =>
                                            handleDeleteVideocaoll(item, i)
                                          }
                                        ></i> */}
                                        {/* <button
                                          type="button"
                                          className="btn-close position-absolute top-0 end-0 text-danger"
                                          aria-label="Close"
                                          onClick={() =>
                                            handleDeleteVideocaoll(item, i)
                                          }
                                          style={{
                                            color: "red",
                                          }}
                                        ></button> */}
                                        <video
                                          style={{ width: "80px", height: "100px", objectFit: "fill" }}
                                          src={
                                            item
                                              ? Config.baseURL + "/" + item
                                              : products
                                          }
                                          className="rounded"
                                          alt="art"
                                        />
                                        <br />
                                        <button
                                          className="btn btn-danger"
                                          style={{
                                            cursor: "pointer",
                                            alignSelf: "center",
                                          }}
                                          onClick={() =>
                                            handleDeleteVideocaoll(item, i)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    );
                                  })}
                                {/* <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body p-4">
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="card-title m-0">
                                  Video for Live Stream
                                </h5>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-primary d-flex align-items-center px-3"
                                  id="add-notes"
                                >
                                  <i className="ti ti-video-plus me-0 me-md-1 fs-4"></i>
                                  <span className="d-none d-md-block font-weight-medium fs-3">
                                    <input
                                      type="file"
                                      multiple
                                      hidden
                                      onChange={(e) => {
                                        handleVideoLiveStream(e.target.files);
                                        setIsError(false);
                                      }}
                                      id="upload-video-stream"
                                    />

                                    <label
                                      for="upload-video-stream"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Add Videos
                                    </label>
                                  </span>
                                </a>
                              </div>
                              {/* {editProfile.Video_Live_Strem.length == 0 && (
                                <Validation isError={isError} />
                              )} */}

                              <div className="row gx-3 mt-4">
                                {editProfile?.Video_Live_Strem?.length !== 0 &&
                                  editProfile?.Video_Live_Strem?.map(
                                    (item, i) => {
                                      return (
                                        <div className="col-3 position-relative p-2">
                                          {/* <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i> */}
                                          {/* <i
                                            className="fa-solid fa-trash-can fs-6 text-danger position-absolute top-0 start-50 translate-middle"
                                            style={{
                                              cursor: "pointer",
                                              position: "absolute",
                                            }}
                                            onClick={() =>
                                              handleDeleteLiveStream(item, i)
                                            }
                                          ></i> */}
                                          <video
                                            style={{ width: "80px", height: "100px", objectFit: "fill" }}
                                            src={
                                              item
                                                ? Config.baseURL + "/" + item
                                                : products
                                            }
                                            className="rounded"
                                            alt="art"

                                          />
                                          <br />
                                          <button
                                            className="btn btn-danger"
                                            style={{
                                              cursor: "pointer",
                                              alignSelf: "center",
                                              width: "80"
                                            }}
                                            onClick={() =>
                                              handleDeleteLiveStream(item, i)
                                            }
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      );
                                    }
                                  )}
                                {/* <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div>
                                <div className="col-3 position-relative p-2">
                                  <i className="ti ti-trash fs-6 text-white position-absolute top-50 start-50 translate-middle"></i>
                                  <img
                                    src={products}
                                    className="rounded img-fluid"
                                    alt="art"
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card w-100 position-relative mb-0">
                        <div className="card-body p-4">
                          {/* <form> */}
                          <div className="col-12">
                            <div className="mb-4">
                              <label
                                for="profile-bio"
                                className="form-label fw-semibold"
                              >
                                Bio
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="profile-bio"
                                name="Bio"
                                value={editProfile?.Bio}
                                // onChange={(e) => handleChange(e)}
                                onChange={(e) => {
                                  handleChange(e);
                                  setIsError(false);
                                }}
                              />
                              {editProfile.Bio == "" && (
                                <Validation isError={isError} />
                              )}
                            </div>
                            <div className="mb-3">
                              <label
                                for="profile-hashtag"
                                className="form-label fw-semibold"
                              >
                                Hashtag
                              </label>
                              <input
                                type="text"
                                required
                                className="form-control"
                                id="profile-hashtag"
                                name="HasTag"
                                value={editProfile?.HasTag}
                                // onChange={(e) => handleChange(e)}
                                onChange={(e) => {
                                  handleChange(e);
                                  setIsError(false);
                                }}
                              />
                              {editProfile.HasTag == "" && (
                                <Validation isError={isError} />
                              )}
                            </div>
                          </div>

                          {/* <div className="mb-3 form-group">
                            <label className="form-label fw-semibold">
                              Profile Image
                            </label>
                            <div className="controls">
                              <input
                                type="file"
                                name="profile-image"
                                className="form-control"
                                required=""
                                aria-invalid="false"
                              />
                            </div>
                          </div> */}

                          {/* <div className="card card-body">
                            <div className="d-flex">
                              <div className="col-8 me-2">
                                <select
                                  className="form-select mr-sm-2"
                                  onChange={(e) =>
                                    setselectedSticker(e.target.value)
                                  }
                                >
                                  <option value="-1">Stickers</option>
                                  {stickerList &&
                                    stickerList.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item?.image}
                                        data-image={
                                          Config.baseURL +
                                          "/upload/fake_profile/stickers/1693730077919.jpeg"
                                        }
                                      >
                                        {item?.image}
                                      </option> // <img src={Config.baseURL + "/" + item?.image}></img>
                                    ))}
                                </select>
                              </div>

                              <div className="col-3 ms-2">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="profile-sticker"
                                  name="Sticker"
                                  placeholder="Sticker count"
                                  onChange={(e) =>
                                    setStickerCount(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-1 ms-2">
                                <button
                                  className="btn btn-primary"
                                  onClick={handleStickerAdd}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div> */}

                          <div className="col-12">
                            <div className="mb-3 form-group">
                              <label className="form-label fw-semibold">
                                Profile Image
                              </label>
                              <div className="controls">
                                <input
                                  type="file"
                                  name="Profile-Pic"
                                  className="form-control"
                                  required=""
                                  aria-invalid="false"
                                  onChange={(e) => {
                                    handleAddProfile(e.target.files[0]);
                                    setIsError(false);
                                  }}
                                />
                                {editProfile.Profile_Pic == "" && (
                                  <Validation isError={isError} />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="card card-body">
                            <div className="row align-items-center">
                              <div className="col-12 col-md-7 field-content">
                                {/* <select
                                  className="form-select"
                                  defaultValue={editProfile.Stickerts}
                                  onChange={(e) => {
                                    setselectedSticker(e.target.value);
                                    setIsError(false);
                                  }}
                                >
                                  <option value="-1">Stickers</option>
                                  {stickerList &&
                                    stickerList.map((item, index) => (
                                      
                                      <option
                                        key={index}
                                        value={item.image}
                                      >
                                        {item.image}
                                      </option>
                                    ))}
                                </select> */}
                                <ReactSelect
                                  value={stickers}
                                  onChange={setStickers}
                                  options={stickerList}
                                  formatOptionLabel={data => (
                                    <div className="country-option d-flex" >
                                      <img src={Config.baseURL + `/${data.image}`} style={{width : "60px", height:"60px", cursor : "pointer"}} alt="country-image" />
                                      <span>{data.label}</span>
                                    </div>
                                  )}
                                />
                                {editProfile.Stickerts.length === 0 && (
                                  <Validation isError={isError} />
                                )}
                              </div>

                              {/* <div className="col-12">
                                <div className="sticker-list">
                                  {stickerList &&
                                    stickerList.map((item, index) => (
                                      <div className="sticker-item" key={index}>
                                        <img
                                          width="80"
                                          height="100"
                                          src={Config.baseURL + item.image}
                                          className="rounded"
                                          alt="art"
                                        />
                                        {console.log("aaaaaaaa" , Config.baseURL  + "/" + item.img)}
                                        {item.image}
                                      </div>
                                    ))}
                                </div>
                              </div> */}

                              <div className="col-12 col-md-3 field-content">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="profile-sticker"
                                  name="Sticker"
                                  placeholder="Sticker count"
                                  onChange={(e) => {
                                    setStickerCount(e.target.value);
                                    setIsError(false);
                                  }}
                                />
                              </div>

                              <div className="col-12 col-md-1 field-content">
                                <button
                                  className="btn btn-primary"
                                  onClick={handleStickerAdd}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* <div className="input-group">
                            <span className="input-group-text">Stickers</span>

                            <div className="dropdown form-group">
                              <button className="dropbtn form-select mr-sm-2 rounded-0">
                                Choose...
                              </button>
                              <div className="dropdown-content">
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker1}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker2}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker3}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker4}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker5}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker6}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker7}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker8}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker1}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker2}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker3}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker4}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker5}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker6}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker7}
                                    width="60"
                                  />
                                </a>
                                <a href="">
                                  <img
                                    className="p-2"
                                    src={sticker8}
                                    width="60"
                                  />
                                </a>
                              </div>
                            </div>

                            <input type="text" className="form-control" />
                          </div> */}

<div className="col-12 my-3">
  <div className="d-flex flex-wrap gap-4">
    {editProfile.Stickerts &&
      editProfile.Stickerts.map((item, index) => (
        <div
          key={index}
          className="d-flex flex-column align-items-center gap-2"
          style={{
            flexBasis: "10%",
            flexShrink: 0,
          }}
        >
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              const filter = editProfile?.Stickerts?.filter((filterItem) => {
                return filterItem !== item;
              });
              setEditProfile({
                ...editProfile,
                Stickerts: filter,
              });
            }}
          ></button>
          <img
            src={Config?.baseURL + "/" + item.img}
            width="25"
            alt="user1"
            className="rounded-circle"
          />
          <span className="fs-3">x {item?.count}</span>
        </div>
      ))}
  </div>

                            {/* <div className="d-flex align-items-center gap-2 ">
                              <img
                                src={sticker2}
                                width="25"
                                alt="user1"
                                className="rounded-circle"
                              />
                              <span className="fs-3">x 32</span>
                            </div> */}
                            {/* <div className="d-flex align-items-center gap-2 ">
                              <img
                                src={sticker3}
                                width="25"
                                alt="user1"
                                className="rounded-circle"
                              />
                              <span className="fs-3">x 12</span>
                            </div>
                            <div className="d-flex align-items-center gap-2 ">
                              <img
                                src={sticker4}
                                width="25"
                                alt="user1"
                                className="rounded-circle"
                              />
                              <span className="fs-3">x 40</span>
                            </div> */}
                          </div>
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
                              {editProfile?._id ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleEdit()}
                                >
                                  Save Changes
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    if (
                                      editProfile?.UserName.trim() === "" ||
                                      editProfile?.Age.trim() === "" ||
                                      editProfile?.Bio.trim() === "" ||
                                      editProfile?.HasTag.trim() === "" ||
                                      editProfile?.Profile_Pic.trim() === ""
                                    ) {
                                      // editProfile
                                      setIsError(true);
                                    } else {
                                      handleAdd();
                                      setIsError(false); // Reset the error if input is valid
                                    }
                                  }}
                                >
                                  Save Changes
                                </button>
                              )}
                              <button
                                className="btn btn-light-danger text-danger"
                                onClick={() => navigate("/admin/profile")}
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditProfilePage;

import React from 'react';
import Page from 'src/components/Page';
import ForgetPasswordPage from 'src/views/component/auth/ForgetPassword';

const ForgetPasswordView = () => {
  return (
    <Page title='Sign In'>
      <ForgetPasswordPage />
    </Page>
  );
};

export default ForgetPasswordView;

import React, { Fragment, useEffect, useState,useRef } from "react";
import chamet from "src/assets/images/apps/Chamet.png";
import { setApiMessage } from "src/helpers/commonFunctions";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import PreLoader from "src/components/Loader";
import LeftSideBar from "../../UI/leftsideBar";
import axios from "axios";
import { Config } from "src/helpers/context";
import ReactSelect from "react-select";
// import Validation from "src/components/Validation";

const NotificationPage = () => {
    // const [isError, setIsError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [appList, setAppdList] = useState([]);
    const [notiTitle, setNotiTitle] = useState("");
    const [notiMessge, setNotiMessge] = useState("");
    const [notiIcon, setNotiIcon] = useState("");
    const [sendAppData, setSendAppData] = useState();
    const fileInputRef = useRef(null); // Create a ref


    const getListApp = async () => {
        try {
            setLoader(true);

            const { status, data } = await axiosInstance.get(URLS.GET_LIST_OF_APP);

            if (status === 200) {
                if (!data?.flags) {
                    console.log("GET_LIST_OF_APP", data?.data);
                    setAppdList(data?.data);
                    //   setApiMessage('success', data?.message);
                } else {
                    setApiMessage("error", data?.error?.[0]?.msg);
                }
                setLoader(false);
            }
        } catch (error) {
            setApiMessage("error", error?.response?.data?.message);
            setLoader(false);
        }
    };

    useEffect(() => {
        getListApp();
    }, []);
    console.log("appList", appList);

    const createNotication = async (data) => {
        const options = optionsBuilder("post", data);
        try {
            const response = await axios.post('https://onesignal.com/api/v1/notifications', data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${sendAppData?.App_Auth_key}`,
                }
            });
            setNotiIcon("");
            setNotiMessge("");
            setNotiTitle("");
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }

    };

    const optionsBuilder = (method, body) => {
        return {
            method,
            url: "https://onesignal.com/api/v1/notifications",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${sendAppData?.App_Auth_key}`,
            },
            body: body
        };
    };

    const handleNotificationsend = async () => {
        const body = {
            app_id: sendAppData?.App_Id,
            included_segments: ["All"],
            headings: {
                en: notiTitle,
            },
            contents: {
                en: notiMessge,
            },
            big_picture: notiIcon,
            ios_attachments: notiIcon,
            ios_sound: "https://example.com/path/to/your/soundfile.mp3",
        };
        console.log('body', body);
        if (sendAppData && sendAppData?.App_Id && sendAppData?.App_Auth_key) {
            await createNotication(body);
        } else {
            setApiMessage('error', "Please select an app htmlFor send notification")
        }
    };


    const handleAppSelect = (item) => {
        console.log('item', item);
        setSendAppData(item);
    };

    const handleAddBGImage = async (file) => {
        try {
            setLoader(true);
            let formData = new FormData();
            formData.append("BGImage", file);
            const { status, data } = await axiosInstance.post(
                URLS.UPDATE_BG_IMAGE,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data", // Set the appropriate content type header
                    },
                }
            );

            if (status === 200) {
                if (!data?.flag) {
                    // getProfileList()
                    //   setApiMessage('success', data?.message);
                    setNotiIcon(Config.baseURL + '/' + data?.path);
                } else {
                    setApiMessage("error", data?.error?.[0]?.msg);
                }
                setLoader(false);
            }
        } catch (error) {
            setApiMessage("error", error?.response?.data?.message);
            setLoader(false);
        }
    };

    const handleCancel = () => {
        setNotiIcon("");       
        setNotiMessge("");     
        setNotiTitle("");       
        setSendAppData(null); 
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    return (
        <Fragment>
            {loader && <PreLoader />}
            <div
                className="page-wrapper"
                id="main-wrapper"
                data-layout="vertical"
                data-navbarbg="skin6"
                data-sidebartype="full"
                data-sidebar-position="fixed"
                data-header-position="fixed"
            >
                <LeftSideBar />

                <div className="body-wrapper">
                    <div style={loader ? { opacity: "0.3" } : {}} className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                {/* <form> */}
                                <div className="col-12">
                                    <div className="mb-3 form-group">
                                        <label className="form-label fw-semibold">
                                            Notification Icon
                                        </label>
                                        <div className="controls">
                                            <input
                                                accept="image/*"
                                                onChange={(e) => handleAddBGImage(e.target.files[0])}
                                                type="file"
                                                name="notification-icon"
                                                className="form-control"
                                                required=""
                                                aria-invalid="false"
                                                ref={fileInputRef} // Assign the ref to the file input
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="notification-title"
                                            className="form-label fw-semibold"
                                        >
                                            Title
                                        </label>
                                        <input
                                            onChange={(e) => {
                                                setNotiTitle(e.target.value);
                                                // setIsError(false);
                                            }}
                                            value={notiTitle}
                                            type="text"
                                            className="form-control"
                                            id="notification-title"
                                            placeholder="Your Title Here"
                                        />
                                        {/* {notiTitle == "" && (
                                            <Validation isError={isError} />
                                        )} */}
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="notification-message"
                                            className="form-label fw-semibold"
                                        >
                                            Message
                                        </label>
                                        <input
                                            onChange={(e) => {
                                                setNotiMessge(e.target.value);
                                                // setIsError(false);
                                            }}
                                            value={notiMessge}
                                            type="text"
                                            className="form-control"
                                            id="notification-message"
                                            placeholder="Your Message Here"
                                        />
                                        {/* {notiMessge == "" && (
                                            <Validation isError={isError} />
                                        )} */}
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="notification-message"
                                            className="form-label fw-semibold"
                                        >
                                            Select Apps
                                        </label>

                                        <div className="dropdown form-group">
                                            {/* <button className="dropbtn form-select mr-sm-2">
                                                Choose App
                                            </button> */}
                                            <ReactSelect
                                               styles={{cursor:'pointer'}}
                                                value={sendAppData?.appName}
                                                onChange={handleAppSelect}
                                                options={appList}
                                                formatOptionLabel={data => (
                                                    <div className="country-option d-flex cursor-pointer" >
                                                        <img className='p-2 rounded-circle' src={Config.baseURL + `/${data.appIcon}`} style={{ width: "60px", height: "60px", cursor: "pointer" }} alt="country-image" />
                                                        <span style={{paddingLeft : "10px" , paddingTop : "15px",alignContent : 'center'}}>{data.appName}</span>
                                                    </div>
                                                )}
                                            />
                                            <div className="dropdown-content">

                                                {sendAppData && <img
                                                    className="p-2 rounded-circle"
                                                    style={{ cursor: "pointer" }}
                                                    src={Config.baseURL + "/" + sendAppData?.appIcon}
                                                    width="60"
                                                    height="60"
                                                />
                                                }

                                                {/* <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a>
                                                    <a href=""><img className="p-2 rounded-circle" src={chamet} width="60" /></a> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 my-3 d-flex gap-4">
                                            <div className="d-flex align-items-center gap-2 ">
                                                <img src={chamet} width="40" alt="user1"
                                                    className="rounded-circle" />
                                                <img src={chamet} width="40" alt="user1"
                                                    className="rounded-circle" />
                                                <img src={chamet} width="40" alt="user1"
                                                    className="rounded-circle" />
                                            </div>
                                        </div> */}
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => handleNotificationsend()}
                                        >
                                            Send Notification
                                        </button>
                                        {/* <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                if (
                                                    notiTitle.trim() === "" || 
                                                    notiMessge.trim() === ""
                                                ) {
                                                    setIsError(true);
                                                } else {
                                                    handleNotificationsend();
                                                    setIsError(false); // Reset the error if input is valid
                                                }
                                            }}
                                        >
                                            Send Notification
                                        </button> */}
                                        <button className="btn btn-light-danger text-danger" onClick={()=>handleCancel()}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default NotificationPage;

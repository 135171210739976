import React, { Fragment, useEffect, useState } from "react";
import user from "src/assets/images/profile/user-1.jpg";
import chamet from "src/assets/images/apps/Chamet.png";
import LeftSideBar from "../../UI/leftsideBar";
import { setApiMessage } from "src/helpers/commonFunctions";
import { URLS } from "src/helpers/constants/urls";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { Config } from "src/helpers/context";
import PreLoader from "src/components/Loader";

const UserListPage = () => {
  const [loader, setLoader] = useState(false);
  const [userProfileList, setUserProfileList] = useState([]);
  const [filterUser, setFilterUser] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState("-1");
  const [limit, setLimit] = useState("50");
  const [appList, setAppList] = useState([]);

  const getUserProfileList = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(
        URLS.GET_USER + `?limit=${limit}&Status=${onlineStatus}`
      );

      if (status === 200) {
        if (!data?.flags) {
          //console.log("GET_USER", data?.data);
          setUserProfileList(data?.data);
          setFilterUser(data?.data)
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  console.log(userProfileList);

  const getListApp = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(URLS.GET_LIST_OF_APP);

      if (status === 200) {
        if (!data?.flags) {
          console.log("GET_LIST_OF_APP", data?.data);
          setAppList(data?.data);
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }

      getUserProfileList();
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);

      getUserProfileList();
    }
  };

  useEffect(() => {
    getUserProfileList();
  }, [onlineStatus, limit, searchText]);

  useEffect(() => {
    getListApp()

  }, []);


  const handleStatusChange = async (e, data) => {
    const status = e.target.checked
    const obj = {
      _id: data._id,
      IsActive: status,
      UserName: data.UserName
    }
    try {
      const response = await axiosInstance.put(
        `${URLS.EDIT_USER_LIST_PROFILE}`,
        obj
      );
      console.log(response);
      getUserProfileList()
      // let {data} = response.data
      // console.log(data);

    } catch (error) {
      console.log(error);
    }
  }

  const handlePackageFilter = async (e) => {
    console.log(typeof e.target.value);
    try {
      if (e.target.value == "0") {
        getUserProfileList()
        return
      }
      const { data } = await axiosInstance.get(
        URLS.GET_USER + `?limit=${limit}&Package_name=${e.target.value}`
      )
      setUserProfileList(data.data)
    } catch (error) {

    }
  }

  console.log("user", userProfileList);

  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />

        <div className="body-wrapper">
          <div
            className="container-fluid"
            style={loader ? { opacity: "0.3" } : {}}
          >
            {/* <div className="card card-body">
              <div className="d-flex">
                <div className="col-2 me-2">
                  <select className="form-select mr-sm-2">
                    <option selected="">All Locations</option>
                    <option value="1">Andhra Pradesh</option>
                    <option value="2">Arunachal Pradesh</option>
                    <option value="3">Assam</option>
                    <option value="4">Bihar</option>
                    <option value="5">Chhattisgarh</option>
                    <option value="6">Goa</option>
                    <option value="7">Gujarat</option>
                    <option value="8">Haryana</option>
                    <option value="9">Himachal Pradesh</option>
                    <option value="10">Jharkhand</option>
                    <option value="11">Karnataka</option>
                    <option value="12">Kerala</option>
                    <option value="13">Madhya Pradesh</option>
                    <option value="14">Maharashtra</option>
                    <option value="15">Manipur</option>
                    <option value="16">Meghalaya</option>
                    <option value="17">Mizoram</option>
                    <option value="18">Nagaland</option>
                    <option value="19">Odisha</option>
                    <option value="20">Punjab</option>
                    <option value="21">Rajasthan</option>
                    <option value="22">Sikkim</option>
                    <option value="23">Tamil Nadu</option>
                    <option value="24">Telangana</option>
                    <option value="25">Tripura</option>
                    <option value="26">Uttar Pradesh</option>
                    <option value="27">Uttarakhand</option>
                    <option value="28">West Bengal</option>
                  </select>
                </div>
                <div className="col-2 me-2">
                  <select className="form-select mr-sm-2">
                    <option selected="">All App</option>
                    <option value="2">Chamet - Live Video Chat App</option>
                  </select>
                </div>
                <div className="col-7">
                  <form className="position-relative">
                    <input
                      type="text"
                      className="form-control product-search ps-5"
                      id="input-search"
                      placeholder="Search Contacts..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ps-3"></i>
                  </form>
                </div>
                <div className="ms-2">
                  <select
                    className="form-select mr-sm-2"
                    onClick={(e) => setLimit(e.target.value)}
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>
              </div>
            </div> */}

            <div className="card card-body">
              <div className="d-flex flex-wrap">
                {/* <div className="col-12 col-md-2 me-2 mb-2">
                  <select className="form-select mr-sm-2">
                    <option selected="">All Locations</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="6">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="8">Haryana</option>
                    <option value="9">Himachal Pradesh</option>
                    <option value="10">Jharkhand</option>
                    <option value="11">Karnataka</option>
                    <option value="12">Kerala</option>
                    <option value="13">Madhya Pradesh</option>
                    <option value="14">Maharashtra</option>
                    <option value="15">Manipur</option>
                    <option value="16">Meghalaya</option>
                    <option value="17">Mizoram</option>
                    <option value="18">Nagaland</option>
                    <option value="19">Odisha</option>
                    <option value="20">Punjab</option>
                    <option value="21">Rajasthan</option>
                    <option value="22">Sikkim</option>
                    <option value="23">Tamil Nadu</option>
                    <option value="24">Telangana</option>
                    <option value="25">Tripura</option>
                    <option value="26">Uttar Pradesh</option>
                    <option value="27">Uttarakhand</option>
                    <option value="28">West Bengal</option>
                  </select>
                </div> */}

                <div className="col-12 col-md-2 me-2 mb-2">
                  <select className="form-select mr-sm-2" onChange={handlePackageFilter}>
                    <option value={0}>All App</option>
                    {
                      appList.map((item) => (
                        console.log(item),
                        <option key={item._id} value={item.Package_name}>{item.appName}</option>
                      ))
                    }
                    {/* <option value="2">Chamet - Live Video Chat App</option> */}
                  </select>
                </div>
                <div className="col-12 col-md-8 me-2 mb-2">
                  <form className="position-relative">
                    {/* <input
                      type="text"
                      className="form-control product-search"
                      id="input-search"
                      placeholder="Search Contacts..."
                      // onChange={(e) => setSearchText(e.target.value)}
                      onChange={(e) => {
                        const value = e.target.value
                        const filterData = userProfileList.filter((item)=>{
                          if(value == ""){
                            getUserProfileList();
                          }else{
                            return item.UserName.toLowerCase().includes(value.toLowerCase())
                          }
                        })
                        setUserProfileList(filterData)
                      }}
                    /> */}
<input
  type="text"
  className="form-control product-search"
  id="input-search"
  placeholder="Search Contacts..."
  onChange={(e) => {
    const value = e.target.value.toLowerCase();
    console.log("hhhhhhhh",value)
    const searchTerms = value.split(" ").filter(term => term.trim() !== ""); // Split by spaces and remove empty terms
    let filteredData = [];
    let filteredLocation = [];

    if (searchTerms.length === 0) {
      // If there are no search terms, reset to the original data.
      setUserProfileList(userProfileList);
      getUserProfileList();
    } 
    else {
      // Filter the data based on username.
      filteredData = filterUser.filter((item) => {
        const userName = item.UserName.toLowerCase();
        const location = item.Location.toLowerCase();
        return userName.includes(value) || location.includes(value) 
      });
      console.log("filteredData" ,filteredData);

      // Filter the data based on location.
      // filteredLocation = userProfileList.filter((item) => {
      //   const location = item.Location.toLowerCase();
      //   return searchTerms.every(term => location.includes(term));
      // });
    }

    // Combine the filtered results and remove duplicates.
    // const combinedResults = [...new Set([...filteredData, ...filteredLocation])];

    // Update the state with the combined results.
    setUserProfileList(filteredData);
  }}
/>



                    <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ps-3"></i>
                  </form>
                </div>
                {/* <div className="col-12 col-md-2 me-2 mb-2">
                  <input
                    type="text"
                    className="form-control product-search"
                    id="input-search"
                    placeholder="Search Location..."
                    onChange={(e) => {
                      const value = e.target.value.toLowerCase();
                      const searchTerms = value.split(" ").filter(term => term.trim() !== ""); 
                      let filteredData;

                      if (searchTerms.length === 0) {
                       
                        filteredData = userProfileList;
                        getUserProfileList();

                      } else {
                   
                        filteredData = userProfileList.filter((item) => {
                          const Location = item.Location.toLowerCase();
                          return searchTerms.every(term => Location.includes(term));
                        });
                      }
                      
                      setUserProfileList(filteredData);
                    }}
                  />
                </div> */}
                <div className="col-12 col-md-1 me-2 mb-1">
                  <select
                    className="form-select mr-sm-2"
                    onClick={(e) => setLimit(e.target.value)}
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addContactModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addContactModalTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header d-flex align-items-center">
                    <h5 className="modal-title">Contact</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <form id="addContactModalTitle">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3 contact-name">
                                <input
                                  type="text"
                                  id="c-name"
                                  className="form-control"
                                  placeholder="Name"
                                />
                                <span className="validation-text text-danger"></span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3 contact-email">
                                <input
                                  type="text"
                                  id="c-email"
                                  className="form-control"
                                  placeholder="Email"
                                />
                                <span className="validation-text text-danger"></span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3 contact-occupation">
                                <input
                                  type="text"
                                  id="c-occupation"
                                  className="form-control"
                                  placeholder="Occupation"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3 contact-phone">
                                <input
                                  type="text"
                                  id="c-phone"
                                  className="form-control"
                                  placeholder="Phone"
                                />
                                <span className="validation-text text-danger"></span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3 contact-location">
                                <input
                                  type="text"
                                  id="c-location"
                                  className="form-control"
                                  placeholder="Location"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="btn-add"
                      className="btn btn-success rounded-pill px-4"
                    >
                      Add
                    </button>
                    <button
                      id="btn-edit"
                      className="btn btn-success rounded-pill px-4"
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-danger rounded-pill px-4"
                      data-bs-dismiss="modal"
                    >
                      {" "}
                      Discard{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-body">
              <div className="table-responsive">
                <table className="table search-table align-middle text-nowrap">
                  <thead className="header-item">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Location</th>
                      <th>Visibility</th>
                      <th>App From</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userProfileList &&
                      userProfileList
                        ?.filter((obj) => obj.UserName?.includes(searchText))
                        .map((item, i) => {

                          let icon = ""
                          let appname = ""
                          appList && appList.map((appitem, appI) => {
                            if (item.Package_name === appitem.Package_name) {
                              icon = appitem.appIcon;
                              appname = appitem.appName
                            }
                          })

                          return (
                            <tr className="search-items" key={i}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      item?.Profile_Pic
                                    }
                                    // src={Config.baseURL + "/" + item?.Profile_Image}
                                    alt="avatar"
                                    className="rounded-circle"
                                    width="35"
                                  />
                                  <div className="ms-3">
                                    <div className="user-meta-info">
                                      <h6
                                        className="user-name mb-0"
                                        data-name="Emma Adams"
                                      >
                                        {item?.UserName}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span
                                  className="usr-email-addr"
                                  data-email="adams@mail.com"
                                >
                                  {item?.UserEmailId}
                                </span>
                              </td>
                              <td>
                                <span className="">{item?.Location}</span>
                              </td>
                              <td>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="IsActive"
                                    defaultChecked={item.IsActive}
                                    onChange={(e) => {
                                      handleStatusChange(e, item);
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <img
                                  src={Config.baseURL + "/" + icon}
                                  alt={appname}
                                  width="35"
                                  height="35"
                                  className="rounded-circle"
                                  data-bs-toggle="tooltip"
                                  title={appname}
                                />
                              </td>
                            </tr>
                          );
                        })}

                    {userProfileList?.filter((obj) =>
                      obj.UserName?.includes(searchText)
                    )?.length === 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            margin: "0px 0px 0px 400px",
                          }}
                        >
                          No Records Found
                        </div>
                      )}
                    {/* <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Olivia Allen"
                              >
                                Olivia Allen
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="allen@mail.com"
                        >
                          allen@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Karnataka, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr>

                    <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Isabella Anderson"
                              >
                                {" "}
                                Isabella Anderson{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="anderson@mail.com"
                        >
                          anderson@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Maharashtra, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr>

                    <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Emma Adams"
                              >
                                Emma Adams
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="adams@mail.com"
                        >
                          adams@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Gujarat, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr>

                    <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Olivia Allen"
                              >
                                Olivia Allen
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="allen@mail.com"
                        >
                          allen@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Karnataka, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr>

                    <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Isabella Anderson"
                              >
                                {" "}
                                Isabella Anderson{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="anderson@mail.com"
                        >
                          anderson@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Maharashtra, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr>

                    <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Emma Adams"
                              >
                                Emma Adams
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="adams@mail.com"
                        >
                          adams@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Gujarat, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr>

                    <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Olivia Allen"
                              >
                                Olivia Allen
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="allen@mail.com"
                        >
                          allen@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Karnataka, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr>

                    <tr className="search-items">
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={user}
                            alt="avatar"
                            className="rounded-circle"
                            width="35"
                          />
                          <div className="ms-3">
                            <div className="user-meta-info">
                              <h6
                                className="user-name mb-0"
                                data-name="Isabella Anderson"
                              >
                                {" "}
                                Isabella Anderson{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="usr-email-addr"
                          data-email="anderson@mail.com"
                        >
                          anderson@mail.com
                        </span>
                      </td>
                      <td>
                        <span className="">Maharashtra, India</span>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          src={chamet}
                          alt="user1"
                          width="35"
                          height="35"
                          className="rounded-circle"
                          data-bs-toggle="tooltip"
                          title="Chamet - Live video chat app"
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserListPage;

import React from 'react';
import { Outlet } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { ToastContainer } from 'react-toastify';

import TopBar from './TopBar';

import { identifiers } from 'src/helpers/constants/identifier';

const DashboardLayout = () => {
  const redux_userData = useSelector((state) => state?.reduxData?.userData);
  const user_statics = redux_userData
    ? redux_userData
    : fetchFromStorage(identifiers.userData);
  const accountMenu = [
    {
      href: '#',
      title: user_statics?.user_name,
      subMenus: [
        {
          href: '#',
          title: 'Sign Out',
          subMenus: [],
        },
      ],
    },
  ];

  return (
    <>
      <div>
        <ToastContainer style={{ padding: '5px' }} limit={1} />
        {/* <TopBar user_statics={user_statics} accountMenu={accountMenu} /> */}

        <div className='layout-wrapper'>
          <div className=''>
            <div className=''>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;

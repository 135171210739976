import React, { Fragment, useEffect, useState } from "react";
import logo from "src/assets/images/logos/dark-logo.svg";
import LeftSideBar from "../../UI/leftsideBar";
import { setApiMessage } from "src/helpers/commonFunctions";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import PreLoader from "src/components/Loader";

const DashboardPage = () => {
  const [loader, setLoader] = useState(true);
  const [dashboardList, setDashboardList] = useState([]);

  const getDashboard = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(URLS.GET_DASHBOARD);

      if (status === 200) {
        if (!data?.flags) {
          console.log("GET_DASHBOARD", data);
          setDashboardList(data);
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);
  console.log("dashboardList", dashboardList);

  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />
        {!loader && (
          <div className="body-wrapper">
            <div
              className="container-fluid"
              style={loader ? { opacity: "0.3" } : {}}
            >
              <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-9">
                      <h4 className="fw-semibold mb-0">Welcome To Dashboard</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 d-flex align-items-stretch">
                  <div className="card w-100 position-relative overflow-hidden">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between">
                        <div>
                          <h4 className="mb-0 fw-semibold">
                            {dashboardList?.totalUser}
                          </h4>
                          <p className="mb-0">Registered Users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 d-flex align-items-stretch">
                  <div className="card w-100 position-relative overflow-hidden">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-end justify-content-between mb-3">
                        <div>
                          <h4 className="mb-0 fw-semibold">
                            {dashboardList?.totalFakeProfile}
                          </h4>
                          <p className="mb-0">Number of Profiles</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 d-flex align-items-stretch">
                  <div className="card w-100 position-relative overflow-hidden">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between">
                        <div>
                          <h4 className="mb-4 fw-semibold ">Users Type</h4>
                          <p
                            className="mb-2 mb-1 badge rounded-pill bg-primary"
                            style={{ marginRight: "5px" }}
                          >
                            Paid - {dashboardList?.totalPaidUser}
                          </p>
                          <p className="fw-normal mb-1 badge rounded-pill bg-warning">
                            Free - {dashboardList?.totalFreeUser}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6 col-lg-3 d-flex align-items-stretch">
                  <div className="card w-100 position-relative overflow-hidden">
                    <div className="card-body p-4">
                      <div className="mb-7 pb-8">
                        <p className="mb-0">30 min Users</p>
                        <h4 className="mb-0 fw-semibold">
                          {dashboardList?.totalUser}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DashboardPage;

import React from 'react';
import Page from 'src/components/Page';
import DashboardPage from 'src/views/component/auth/Dashboard';

const DashboardView = () => {
  return (
    <Page title='Sign In'>
      <DashboardPage />
    </Page>
  );
};

export default DashboardView;

import React from 'react';
import Page from 'src/components/Page';
import ForgetPasswordConfirmPage from 'src/views/component/auth/forgotPasswordConfirm';

const ForgetPasswordConfirmView = () => {
  return (
    <Page title='Sign In'>
      <ForgetPasswordConfirmPage />
    </Page>
  );
};

export default ForgetPasswordConfirmView;

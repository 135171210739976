import React, { Fragment, useEffect, useState } from "react";
import logo from "src/assets/images/logos/dark-logo.svg";
import sticker1 from "src/assets/images/stickers/sticker(1).png";
import sticker2 from "src/assets/images/stickers/sticker(2).png";
import sticker3 from "src/assets/images/stickers/sticker(3).png";
import sticker4 from "src/assets/images/stickers/sticker(4).png";
import sticker5 from "src/assets/images/stickers/sticker(5).png";
import sticker6 from "src/assets/images/stickers/sticker(6).png";
import axiosInstance from "src/helpers/axios/axiosInstance";
import PreLoader from "src/components/Loader";
import LeftSideBar from "../../UI/leftsideBar";
import { URLS } from "src/helpers/constants/urls";
import { Navigate, useNavigate } from "react-router-dom";
import { setApiMessage } from "src/helpers/commonFunctions";
import { Config } from "src/helpers/context";
import Validation from "src/components/Validation";

const StickersPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [stickerList, setStickerList] = useState([]);
  const [sticker, setSticker] = useState([]);
  // const [imageValue, setImageValue] = useState("");

  const getSticker = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(URLS.GET_STICKERT);

      if (status === 200) {
        if (!data?.flags) {
          console.log("GET_STICKERT", data?.data);
          setStickerList(data?.data);
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleStickerChoose = async (file) => {
    try {
      setLoader(true);
      setSticker(file);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // const handleStickerDiscard = async () => {
  //   try {
  //     setLoader(true);
  //     setSticker('')
  //     setLoader(false)
  //   } catch (error) {
  //     console.log(error);
  //     setLoader(false);
  //   }
  // };

  const handleDiscard = () => {
    setSticker([]);
    const fileInput = document.getElementById("stickerInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleStickerUpload = async () => {
    try {
      setLoader(true);
      let formData = new FormData();
      for (let key of sticker) {
        formData.append("stickers", key);
      }
      const { status, data } = await axiosInstance.post(
        URLS.ADD_STICKER,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          console.log("ADD_STICKER", data);
          setStickerList(data?.data);
          // for (let i = 0; i < data?.data.length; i++) {
          //   console.log("object", data?.data[i]);
          //   const element = data?.data[i];
          //   setStickerList([...stickerList, element]);
          //   // setImageValue("");
          // }
          // getProfileList()
          //   setApiMessage('success', data?.message);
          // setStickerList([...data?.data]);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.delete(
        URLS.DELETE_STICKER + `?id=${id}`
      );

      if (status === 200) {
        if (!data?.flags) {
          console.log("DELETE_STICKER", data?.data);
          getSticker();
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getSticker();
  }, []);
  console.log("stickerList", stickerList);

  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />

        <div className="body-wrapper">
          <div
            className="container-fluid"
            style={loader ? { opacity: "0.3" } : {}}
          >
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {stickerList?.map((sticker, i) => (
                    <div key={i} className="col-md-4 col-lg-2">
                      <div
                        className="card text-center alert-dismissible fade show alert p-0 card-hover bg-white"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => handleDelete(sticker?._id)}
                        ></button>
                        <div className="p-3 d-block mt-3">
                          <img
                            src={
                              sticker && sticker?.image
                                ? Config.baseURL + "/" + sticker?.image
                                : sticker1
                            }
                            alt="avatar"
                            className="rounded-circle"
                            width="75"
                            height="75"
                          />

                          {/* <a
                            href="#"
                            className="btn btn-primary text-white  d-block w-100"
                          >
                            Replace
                          </a> */}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="col-md-4 col-lg-2">
                    <div
                      className="card text-center alert-dismissible fade show alert p-0 card-hover bg-white"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <div className="p-3 d-block mt-3">
                        <img
                          src={sticker2}
                          width="75"
                          className="rounded-circle img-fluid"
                        />
                        <a
                          href="#"
                          className="btn btn-primary text-white  d-block w-100"
                        >
                          Replace
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-2">
                    <div
                      className="card text-center alert-dismissible fade show alert p-0 card-hover bg-white"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <div className="p-3 d-block mt-3">
                        <img
                          src={sticker3}
                          width="75"
                          className="rounded-circle img-fluid"
                        />
                        <a
                          href="#"
                          className="btn btn-primary text-white  d-block w-100"
                        >
                          Replace
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-2">
                    <div
                      className="card text-center alert-dismissible fade show alert p-0 card-hover bg-white"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <div className="p-3 d-block mt-3">
                        <img
                          src={sticker4}
                          width="75"
                          className="rounded-circle img-fluid"
                        />
                        <a
                          href="#"
                          className="btn btn-primary text-white  d-block w-100"
                        >
                          Replace
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-2">
                    <div
                      className="card text-center alert-dismissible fade show alert p-0 card-hover bg-white"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <div className="p-3 d-block mt-3">
                        <img
                          src={sticker5}
                          width="75"
                          className="rounded-circle img-fluid"
                        />
                        <a
                          href="#"
                          className="btn btn-primary text-white  d-block w-100"
                        >
                          Replace
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-2">
                    <div
                      className="card text-center alert-dismissible fade show alert p-0 card-hover bg-white"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <div className="p-3 d-block mt-3">
                        <img
                          src={sticker6}
                          width="75"
                          className="rounded-circle img-fluid"
                        />
                        <a
                          href="#"
                          className="btn btn-primary text-white  d-block w-100"
                        >
                          Replace
                        </a>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <form> */}
                <div className="col-12">
                  <div className="mb-3 form-group">
                    <label className="form-label fw-semibold">
                      Add Stickers
                    </label>
                    <div className="controls">
                      <input
                        type="file"
                        name="profile-stickers"
                        className="form-control"
                        id="stickerInput"
                        accept=".jpg, .jpeg, .png, .gif"
                        multiple
                        // defaultValue={imageValue}
                        aria-invalid="false"
                        onChange={(e) => {
                          // console.log(e.target.files[0].name);
                          handleStickerChoose(e.target.files);
                          setIsError(false);
                          // setImageValue(e.target.files[0].name);
                        }}
                      />
                      {sticker?.image == "" && <Validation isError={isError} />}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        // if (sticker?.image.trim() === "") {
                        //   setIsError(true);
                        // } else {
                        handleStickerUpload();
                        setSticker("");
                        // }
                      }}
                    >
                      Add Sticker
                    </button>
                    <button
                      className="btn btn-light-danger text-danger"
                      // onClick={() => navigate("/admin/stickers")}
                      onClick={() => handleDiscard()}
                    >
                      Discard
                    </button>
                    {/* <button className="btn btn-light-danger text-danger" onClick={()=> handleStickerDiscard()}>
                        Discard
                      </button> */}
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StickersPage;

import React from 'react';

import Page from 'src/components/Page';
import NotificationPage from 'src/views/component/auth/Notification';

const NotificationView = () => {
  return (
    <Page title='Sign In'>
      <NotificationPage />
    </Page>
  );
};

export default NotificationView;

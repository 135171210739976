import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const DashboardLayout = () => {
  return (
    <div>
      <ToastContainer style={{ padding: '5px' }} limit={1} />
      <div className='layout-wrapper'>
        <div className=''>
          <div className=''>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;

import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/images/logos/dark-logo.svg";
import PreLoader from "src/components/Loader";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { setApiMessage } from "src/helpers/commonFunctions";
import { identifiers } from "src/helpers/constants/identifier";
import { URLS } from "src/helpers/constants/urls";
import { saveToStorage } from "src/helpers/context";
import LeftSideBar from "../../UI/leftsideBar";

const ForgetPasswordConfirmPage = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const { email } = state;
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const handlePasswordReset = async () => {
    try {
      setLoader(true);
      let sendData = {
        oldPWD: newPass,
        newPWD: confirmPass,
        // UserName:"admin"
      };
      const { status, data } = await axiosInstance.put(
        URLS.UPDATE_ADMIN_PASSWORD,
        sendData
      );

      if (status === 200) {
        if (!data?.flags) {
          //   saveToStorage(identifiers?.authData, data);
          setApiMessage("success", data?.message);
          // navigate("/admin/sign-in");
          setNewPass("");
          setConfirmPass("")
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };
  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        style={loader ? { opacity: "0.3" } : {}}
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />
        <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center w-100">
            <div className="row justify-content-center w-100">
              <div className="col-md-8 col-lg-6 col-xxl-3">
                <div className="card mb-0">
                  <div className="card-body">
                    <a
                      href="/"
                      className="text-nowrap logo-img text-center d-block py-3 w-100"
                    >
                      <img src={logo} width="180" alt="" />
                    </a>
                    <p className="text-center">Change your password</p>
                    {/* <form> */}
                    {/* <div className="mb-3">
                      <label for="exampleInputOtp" className="form-label">
                        OTP
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputOtp"
                        aria-describedby="emailHelp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label for="exampleInputNewPass" className="form-label">
                        Old Password
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputNewPass"
                        aria-describedby="emailHelp"
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleInputConfirmPass"
                        className="form-label"
                      >
                        New Password
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputConfirmPass"
                        aria-describedby="emailHelp"
                        value={confirmPass}
                        onChange={(e) => setConfirmPass(e.target.value)}
                      />
                    </div>
                    <button
                      // href='/dashboard'
                      className="btn btn-primary w-100 py-8 fs-4 rounded-2"
                      onClick={() => handlePasswordReset()}
                    >
                      Change Password
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgetPasswordConfirmPage;

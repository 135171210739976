import React from 'react';
import Page from 'src/components/Page';
import SettingsPage from 'src/views/component/auth/Settings';

const SettingsView = () => {
  return (
    <Page title='Sign In'>
      <SettingsPage />
    </Page>
  );
};

export default SettingsView;

import React, { Fragment, useEffect, useState } from "react";
import chamet from "src/assets/images/apps/Chamet.png";
import LeftSideBar from "../../UI/leftsideBar";
import { setApiMessage } from "src/helpers/commonFunctions";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import { Config } from "src/helpers/context";
import PreLoader from "src/components/Loader";
import Validation from "src/components/Validation";
import { useNavigate } from "react-router-dom";

const SettingsPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [appList, setAppdList] = useState([]);
  const [isError, setIsError] = useState(false);

  const [image, setimage] = useState();
  const [addApplication, setAddApplication] = useState({
    Image: null,
    appName: "",
    App_Id: "",
    Package_name: "",
    consoleLink: "",
    App_Auth_key:"",
  });
  console.log("addApplication", addApplication);

  const getListApp = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(URLS.GET_LIST_OF_APP);

      if (status === 200) {
        if (!data?.flags) {
          console.log("GET_LIST_OF_APP", data?.data);
          setAppdList(data?.data);
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleAddApplication = async () => {
    try {
      setLoader(true);
      console.log(addApplication);
      let formData = new FormData();
      formData.append("Image", addApplication?.Image);
      formData.append("appName", addApplication?.appName);
      formData.append("App_Id", addApplication?.App_Id);
      formData.append("Package_name", addApplication?.Package_name);
      formData.append("consoleLink", addApplication?.consoleLink);
      formData.append("App_Auth_key",addApplication?.App_Auth_key);
      const { status, data } = await axiosInstance.post(
        URLS.APP_INSERT,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flags) {
          console.log("APP_INSERT", data);
          getListApp();
          // getProfileList()
          //   setApiMessage('success', data?.message);
          // setAddApplication({ ...addApplication, Image: data?.path });
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, files } = e.target;
    if (name === "Image") {
      setAddApplication({ ...addApplication, [name]: files[0] });
    } else {
      setAddApplication({ ...addApplication, [name]: value });
    }
  };

  const handleImageUpload = (e) => {
    try {
      setAddApplication({ ...addApplication, Image: e.target.files[0] });
    } catch (error) {
      console.log(error);
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleReset = () => {
    setAddApplication({ ...addApplication, Image: null });
  };

  const handleDiscard = () => {
    setAddApplication({
      ...addApplication,
      appName: "",
      Package_name: "",
      consoleLink: "",
      App_Id: "",
      App_Auth_key:"",
    });
  };

  const handleDelete = async (id) => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.delete(
        URLS.DELETE_APPLICATION + `?id=${id}`
      );

      if (status === 200) {
        if (!data?.flags) {
          console.log("DELETE_APPLICATION", data?.data);
          getListApp();
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getListApp();
  }, []);
  console.log("appList", appList);

  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />

        <div className="body-wrapper">
          <div
            className="container-fluid"
            style={loader ? { opacity: "0.3" } : {}}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-body">
                  <div className="table-responsive">
                    <table className="table search-table align-middle text-nowrap">
                      <thead className="header-item">
                        <tr>
                          <th>App</th>
                          <th>Console</th>
                          <th>Package Name</th>
                          <th>App Auth key</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {appList &&
                          appList?.map((item, i) => {
                            return (
                              <tr key={i} className="search-items">
                                <td>
                                  <div className="d-flex align-items-center">
                                    {/* <img
                                      src={chamet}
                                      alt="avatar"
                                      className="rounded-circle"
                                      width="35"
                                    /> */}
                                    <img
                                      src={
                                        item && item?.appIcon
                                          ? Config.baseURL + "/" + item?.appIcon
                                          : chamet
                                      }
                                      alt="avatar"
                                      className="rounded-circle"
                                      width="35"
                                      height="35"
                                    />
                                    <div className="ms-3">
                                      <div className="user-meta-info">
                                        <h5
                                          className="user-name mb-0"
                                          data-name="Emma Adams"
                                        >
                                          {item?.appName}
                                        </h5>
                                        {/* <span className="user-work fs-3">
                                            Live Video Chat & Meet
                                          </span> */}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href={item?.consoleLink}
                                    className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                                    target="_blank"
                                  >
                                    Link
                                  </a>
                                </td>
                                <td>
                                  <span>{item?.Package_name}</span>
                                </td>
                                <td>
                                  <span>{item?.App_Auth_key}</span>
                                </td>
                                <td>
                                  <div className="action-btn">
                                    <a
                                      // href="javascript:void(0)"
                                      className="text-dark delete ms-2"
                                    >
                                      <i
                                        className="fa-solid text-danger fa-trash-can"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDelete(item?._id)}
                                      ></i>
                                      <i className="ti ti-trash text-danger fs-5"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}

                        {/* <tr className="search-items">
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={chamet}
                                alt="avatar"
                                className="rounded-circle"
                                width="35"
                              />
                              <div className="ms-3">
                                <div className="user-meta-info">
                                  <h5
                                    className="user-name mb-0"
                                    data-name="Emma Adams"
                                  >
                                    Chamet
                                  </h5>
                                  <span className="user-work fs-3">
                                    Live Video Chat & Meet
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.hkfuliao.chamet&hl=en&gl=US"
                              className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                              target="_blank"
                            >
                              Link
                            </a>
                          </td>
                          <td>
                            <span>com.hkfuliao.chamet</span>
                          </td>
                          <td>
                            <div className="action-btn">
                              <a
                                href="javascript:void(0)"
                                className="text-dark delete ms-2"
                              >
                                <i className="ti ti-trash text-danger fs-5"></i>
                              </a>
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <h5 className="card-title fw-semibold my-3">
                      Add Applications
                    </h5>
                    <div className="col-12 d-flex align-items-stretch">
                      <div className="card w-100 position-relative overflow-hidden">
                        <div className="card-body p-4">
                          <div className="text-center">
                            <img
                              src={
                                addApplication?.Image
                                  ? URL.createObjectURL(addApplication?.Image)
                                  : chamet
                              }
                              alt=""
                              className="img-fluid rounded-circle"
                              width="120"
                              height="120"
                            />

                            {/* <img
                              src={
                                addApplication && addApplication?.Profile_Pic
                                  ? Config.baseURL +
                                    "/" +
                                    addApplication?.Profile_Pic
                                  : products
                              }
                              alt=""
                              className="img-fluid rounded"
                              width="80"
                              height="80"
                            /> */}

                            <div className="d-flex align-items-center justify-content-center my-4 gap-3">
                              <input
                                name="Image"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleImageUpload(e)}
                                id="upload-setting-image"
                              />
                              <label
                                className="btn btn-primary"
                                htmlFor="upload-setting-image"
                              >
                                Upload
                              </label>
                              <button
                                className="btn btn-outline-danger"
                                onClick={() => handleReset()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                          {/* <form> */}
                          <div className="mb-4">
                            <label
                              htmlFor="app-name"
                              className="form-label fw-semibold"
                            >
                              App Name
                            </label>
                            <input
                              name="appName"
                              // onChange={(e) => handleChange(e)}
                              onChange={(e) => {
                                handleChange(e);
                                setIsError(false);
                              }}
                              className="form-control"
                              id="app-name"
                              value={addApplication?.appName}
                            />
                            {addApplication?.appName == "" && (
                              <Validation isError={isError} />
                            )}
                          </div>
                          <div className="row pt-3">
                            <div className="col-md-6 mb-4">
                              <label
                                htmlFor="App-Id"
                                className="form-label fw-semibold"
                              >
                                App ID 
                              </label>
                              <input
                                name="App_Id"
                                // onChange={(e) => handleChange(e)}
                                onChange={(e) => {
                                  handleChange(e);
                                  setIsError(false);
                                }}
                                className="form-control"
                                id="App-Id"
                                value={addApplication?.App_Id}
                              />

                              {addApplication?.App_Id == "" && (
                                <Validation isError={isError} />
                              )}
                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                htmlFor="app-auth-key"
                                className="form-label fw-semibold"
                              >
                                Console Link{" "}
                              </label>
                              <input
                                name="consoleLink"
                                // onChange={(e) => handleChange(e)}
                                onChange={(e) => {
                                  handleChange(e);
                                  setIsError(false);
                                }}
                                className="form-control"
                                id="app-auth-key"
                                value={addApplication?.consoleLink}
                              />
                              {/* <Validation isError={isError} /> */}
                              {addApplication?.consoleLink == "" && (
                                <Validation isError={isError} />
                              )}
                            </div>
                          </div>


                          <div className="row pt-3">
                            <div className="col-md-6 mb-4">
                            <label
                              htmlFor="app-package"
                              className="form-label fw-semibold"
                            >
                              Package Name
                            </label>
                            <input
                              name="Package_name"
                              className="form-control"
                              id="app-package"
                              // onChange={(e) => handleChange(e)}
                              onChange={(e) => {
                                handleChange(e);
                                setIsError(false);
                              }}
                              value={addApplication?.Package_name}
                            />

                            {addApplication?.Package_name == "" && (
                              <Validation isError={isError} />
                            )}
                            </div>
                            <div className="col-md-6 mb-4">
                              <label
                                htmlFor="app-auth-key"
                                className="form-label fw-semibold"
                              >
                                App Auth key 
                              </label>
                              <input
                                name="App_Auth_key"
                                // onChange={(e) => handleChange(e)}
                                onChange={(e) => {
                                  handleChange(e);
                                  setIsError(false);
                                }}
                                className="form-control"
                                id="app-auth-key"
                                value={addApplication?.App_Auth_key}
                              />
                              {/* <Validation isError={isError} /> */}
                              {addApplication?.App_Auth_key == "" && (
                                <Validation isError={isError} />
                              )}
                            </div>
                          </div>


                          {/* <div className="mb-2">
                            <label
                              htmlFor="app-package"
                              className="form-label fw-semibold"
                            >
                              Package Name
                            </label>
                            <input
                              name="Package_name"
                              className="form-control"
                              id="app-package"
                              // onChange={(e) => handleChange(e)}
                              onChange={(e) => {
                                handleChange(e);
                                setIsError(false);
                              }}
                              value={addApplication?.Package_name}
                            />

                            {addApplication?.Package_name == "" && (
                              <Validation isError={isError} />
                            )}
                          </div> */}
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
                              {/* <button
                                className="btn btn-primary"
                                onClick={() => handleAddApplication()}
                              >
                                Add Application
                              </button> */}

                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  addApplication.appName.trim() === "" ||
                                    addApplication.App_Id.trim() === "" ||
                                    addApplication.consoleLink.trim() === "" ||
                                    addApplication.Package_name.trim() === ""||
                                    addApplication.App_Auth_key.trim() === ""
                                    ? setIsError(true)
                                    : (() => {
                                      handleAddApplication();
                                      handleDiscard();
                                      setIsError(false);
                                    })();
                                }}
                              >
                                Add Suggestions
                              </button>

                              <button
                                className="btn btn-outline-danger btn-light-danger text-danger"
                                onClick={() => handleDiscard()}
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                /
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SettingsPage;

import React from 'react';

const Loader = () => {
  return (
    <div className="loader-container position-absolute" style={{top : "50%",left : "50%",zIndex : "1"}}>
      <div className="spinner-border text-primary" role="status">
      </div>
    </div>
  );
};

export default Loader;
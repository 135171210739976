import React from "react";
import logo from "src/assets/images/logos/dark-logo.svg";
import { useNavigate, NavLink } from "react-router-dom";
import { clearStorage } from "src/helpers/context";

const LeftSideBar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearStorage();
    navigate("/");
  };

  return (
    <div>
      <aside className="left-sidebar">
        <div>
          <div className="brand-logo d-flex align-items-center justify-content-between">
            <a href="./index.html" className="text-nowrap logo-img">
              <img src={logo} width="180" alt="" />
            </a>
            <div
              className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
              id="sidebarCollapse"
            >
              <i className="ti ti-x fs-8"></i>
            </div>
          </div>

          <nav className="sidebar-nav scroll-sidebar my-2" data-simplebar="">
            <ul id="sidebarnav">
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/dashboard"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/dashboard")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-layout-dashboard"></i> */}
                    <i className="fa-solid fa-table-columns"></i>
                  </span>
                  <span className="hide-menu">Dashboard</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/chat"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/chat")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-message-dots"></i> */}
                    <i className="fa-regular fa-comment-dots"></i>
                  </span>
                  <span className="hide-menu">Chats</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/profile"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/profile")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-user-circle"></i> */}
                    <i className="fa-regular fa-user"></i>
                  </span>
                  <span className="hide-menu">Profiles</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/user-list"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/user-list")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-list-details"></i> */}
                    <i className="fa-solid fa-list-ul"></i>
                  </span>
                  <span className="hide-menu">User-Lists</span>
                </NavLink>
              </li>

              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/stickers"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/stickers")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-cards"></i> */}
                    <i className="fa-regular fa-note-sticky"></i>
                  </span>
                  <span className="hide-menu">Stickers</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/notification"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/notification")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-bell-ringing"></i> */}
                    <i className="fa-regular fa-bell"></i>
                  </span>
                  <span className="hide-menu">Notification</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/chat-suggestions"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/chat-suggestions")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-help"></i> */}
                    <i className="fa-sharp fa-regular fa-circle-question"></i>
                  </span>
                  <span className="hide-menu">Chat Suggestions</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/settings"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/settings")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-settings"></i> */}
                    <i className="fa fa-gear"></i>
                  </span>
                  <span className="hide-menu">Settings</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <NavLink
                  to="/admin/forgot-password-confirm"
                  className="sidebar-link"

                  style={{ textDecoration: "none" }}
                  onClick={() => navigate("/admin/forgot-password-confirm")}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-message-dots"></i> */}
                    <i class="fa-regular fa-pen-to-square"></i>
                  </span>
                  <span className="hide-menu">Change Password</span>
                </NavLink>
              </li>
              <li className="sidebar-item" style={{ cursor: "pointer" }}>
                <a
                  className="sidebar-link"
                  style={{ textDecoration: "none" }}
                  onClick={() => handleLogout()}
                  aria-expanded="false"
                >
                  <span>
                    {/* <i className="ti ti-login"></i> */}
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  <span className="hide-menu">Logout</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default LeftSideBar;

import React, {useEffect, Fragment,useState, createContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { publicRoute, adminRoutes } from 'src/routes';
import { useSelector } from 'react-redux';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from './helpers/constants/identifier';
import { SocketContext, socket } from './helpers/context/socket';
import 'src/assets/scss/styles.scss';
// import "bootstrap/dist/css/bootstrap.min.css";

const Context = createContext();

const App = () => {
  const reduxAuth = useSelector((state) => state?.reduxData?.authData);
  const localAuth = fetchFromStorage(identifiers?.authData);
  const auth = reduxAuth ? reduxAuth : localAuth;

  const viewRoutes = auth ? adminRoutes : publicRoute;

  const routing = useRoutes(viewRoutes);


  return (
    <Fragment>
      <SocketContext.Provider value={{ socket }}>
        {routing}
      </SocketContext.Provider>
    </Fragment>
  );
};

export default App;
export { Context as IntlContext };

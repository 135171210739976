import React from 'react';

import Page from 'src/components/Page';
import EditProfilePage from 'src/views/component/auth/EditProfile';

const EditProfileView = () => {
  return (
    <Page title='Sign In'>
      <EditProfilePage />
    </Page>
  );
};

export default EditProfileView;

import React, { Fragment, useEffect, useState } from "react";
import chamet from "src/assets/images/apps/Chamet.png";
import axiosInstance from "src/helpers/axios/axiosInstance";
import LeftSideBar from "../../UI/leftsideBar";
import PreLoader from "src/components/Loader";
import { setApiMessage } from "src/helpers/commonFunctions";
import { URLS } from "src/helpers/constants/urls";
import Validation from "src/components/Validation";

const ChatSuggestionsPage = () => {
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [chatSuggestionList, setChatSuggestionList] = useState([]);
  const [addSuggestion, setAddSuggestion] = useState({
    _id: "",
    txt: "",
  });

  const getChatSuggestion = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(
        URLS.GET_LIST_CHAT_SUGGESTION
      );

      if (status === 200) {
        if (!data?.flags) {
          setChatSuggestionList(data?.data);
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleAddSuggestion = async () => {
    let sendData = {
      txt: addSuggestion?.txt,
    };
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.post(
        URLS.ADD_CHAT_SUGGESTION,
        sendData
      );

      if (status === 200) {
        if (!data?.flags) {
          //
          getChatSuggestion();
          setAddSuggestion({ ...addSuggestion, txt: "" });
          // getProfileList()
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
          setApiMessage("error", data?.errors?.[0]?.msg);
        }

        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleDiscard = () => {
    setAddSuggestion({
      ...addSuggestion,
      txt: "",
    });
  };

  const handleDelete = async (id) => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.delete(
        URLS.DELETE_CHAT_SUGGESTION + `?id=${id}`
      );

      if (status === 200) {
        if (!data?.flags) {
          getChatSuggestion();
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getChatSuggestion();
  }, []);

  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />

        <div className="body-wrapper">
          <div
            className="container-fluid"
            style={loader ? { opacity: "0.3" } : {}}
          >
            <div className="card">
              <div className="card-body">
                {chatSuggestionList?.map((chatSuggestion, i) => (
                  <div
                    key={i}
                    className="d-flex justify-content-between mb-2 w-100 bg-light text-black font-medium d-flex align-items-center p-3 rounded"
                  >
                    <p className="mb-0">{chatSuggestion?.txt}</p>
                    <div>
                      {/* <a href=""><i className="ti ti-arrow-up fs-4 ms-2 text-primary"></i></a>
                                        <a href=""><i className="ti ti-arrow-down fs-4 ms-2 text-primary"></i></a> */}
                      <a
                        onClick={() => handleDelete(chatSuggestion?._id)}
                        className="text-danger ms-2 edit"
                      >
                        <i
                          className="fa-solid fa-trash-can"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i className="ti ti-trash fs-5"></i>
                      </a>
                      <a href="">
                        <i className="ti ti-trash fs-4 ms-2 text-danger"></i>
                      </a>
                    </div>
                  </div>
                ))}
                {/* <div className="d-flex justify-content-between mb-2 w-100 bg-light text-black font-medium d-flex align-items-center p-3 rounded">
                                    <p className="mb-0">2. How are you?</p>
                                    <div>
                                        <a href=""><i className="ti ti-arrow-up fs-4 ms-2 text-primary"></i></a>
                                        <a href=""><i className="ti ti-arrow-down fs-4 ms-2 text-primary"></i></a>
                                        <a href=""><i className="ti ti-trash fs-4 ms-2 text-danger"></i></a>
                                    </div>
                                </div> */}
                {/* <form> */}
                <div className="col-12">
                  <div className="my-4">
                    <label
                      htmlFor="message-suggestions"
                      className="form-label fw-semibold"
                    >
                      Message Suggestions
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="message-suggestions"
                      placeholder="Your Suggestions Here"
                      name="suggestion"
                      value={addSuggestion?.txt}
                      onChange={(e) => {
                        setAddSuggestion({
                          ...addSuggestion,
                          txt: e.target.value,
                        });
                        setIsError(false);
                      }}
                    />
                    {addSuggestion?.txt == "" && (
                      <Validation isError={isError} />
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        if (addSuggestion.txt.trim() === "") {
                          setIsError(true);
                        } else {
                          handleAddSuggestion();
                          setIsError(false); // Reset the error if input is valid
                        }
                      }}
                    >
                      Add Suggestions
                    </button>
                    <button
                      className="btn btn-light-danger text-danger"
                      onClick={() => handleDiscard()}
                    >
                      Discard
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatSuggestionsPage;

import React, { Fragment, useEffect, useState } from "react";
import user from "src/assets/images/profile/user-1.jpg";
import LeftSideBar from "../../UI/leftsideBar";
import { useNavigate } from "react-router-dom";
import { clearStorage, saveToStorage } from "src/helpers/context";
import { Config } from "src/helpers/context";
import { setApiMessage } from "src/helpers/commonFunctions";
import { URLS } from "src/helpers/constants/urls";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { identifiers } from "src/helpers/constants/identifier";
import PreLoader from "src/components/Loader";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const [limit, setLimit] = useState("50");
  const [onlineStatus, setOnlineStatus] = useState("-1");
  const [searchText, setSearchText] = useState("");
  const [editProfile, setEditProfile] = useState({
    _id: "",
    UserName: "",
    Profile_Pic: "",
    Bg_Image: "",
    IsLive: true,
    IsActive: true,
    Status: 1,
    Age: "",
    G_Img: [],
    Story_Img: [],
    Video_Call: [],
    Video_Live_Strem: [],
    Bio: "",
    HasTag: "",
    Stickerts: [],
  });

  const getProfileList = async () => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.get(
        URLS.GET_PROFILE + `?limit=${limit}&Status=${onlineStatus}`
      );
      if (status === 200) {
        if (!data?.flags) {
          setProfileList(data?.data);
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoader(true);

      const { status, data } = await axiosInstance.delete(
        URLS.DELETE_PROFILE + `?id=${id}`
      );

      if (status === 200) {
        if (!data?.flags) {
          getProfileList();
          //   setApiMessage('success', data?.message);
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };




  useEffect(() => {
    getProfileList();
  }, [onlineStatus, limit, searchText]);

  useEffect(() => {
    getProfileList();
  }, []);

  const handleStatusChange = async (e, data) => {
    const status = e.target.checked
    const obj = {
      _id: data._id,
      IsActive: status,
      UserName: data.UserName
    }
    try {
      await axiosInstance.put(
        `${URLS.EDIT_FAKE_PROFILE}`,
        obj
      );

    } catch (error) {
      console.log(error);
    }
  }

  console.log(profileList);

  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />

        <div className="body-wrapper">
          <div
            className="container-fluid" style={loader ? { opacity: "0.3" } : {}}
          >
            {/* <div className="card card-body">
              <div className="d-flex">
                <div className="col-2 me-2">
                  <select
                    className="form-select mr-sm-2"
                    onClick={(e) => setOnlineStatus(e.target.value)}
                  >
                    <option value="-1">All</option>
                    <option value="2">Live</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </select>
                </div>
                <div className="col-7">
                  <form className="position-relative">
                    <input
                      type="text"
                      className="form-control product-search ps-5"
                      id="input-search"
                      placeholder="Search Contacts..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ps-3"></i>
                  </form>
                </div>
                <div className="col-2 ms-2">
                  <select
                    className="form-select mr-sm-2"
                    onClick={(e) => setLimit(e.target.value)}
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>
                <div className="col-1 ms-2">
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/admin/add-profile")}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="card card-body"

            >
              <div className="row">
                <div className="col-12 col-sm-6 mb-2 col-md-3">
                  <select
                    className="form-select"
                    onClick={(e) => setOnlineStatus(e.target.value)}
                  >
                    <option value="-1" checked={true}>
                      All
                    </option>
                    <option value="2">Live</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </select>
                </div>
                <div className="col-12 col-sm-6 mb-2 col-md-4">
                  <form className="position-relative">
                    <input
                      type="text"
                      className="form-control product-search"
                      id="input-search"
                      placeholder="Search Contacts..."
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase();
                        const searchTerms = value.split(" ").filter(term => term.trim() !== ""); // Split by spaces and remove empty terms
                        let filteredData;

                        if (searchTerms.length === 0) {
                          // If there are no search terms, reset to the original data.
                          filteredData = profileList;
                          getProfileList();

                        } else {
                          // Filter the data based on multiple search terms.
                          filteredData = profileList.filter((item) => {
                            const userName = item.UserName.toLowerCase();
                            return searchTerms.every(term => userName.includes(term));
                          });
                        }
                        // Update the state with the filtered data.
                        setProfileList(filteredData);
                      }}
                    />
                    <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ps-3"></i>
                  </form>
                </div>
                <div className="col-12 col-sm-6 mb-2 col-md-3">
                  <select
                    className="form-select"
                    onClick={(e) => setLimit(e.target.value)}
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>
                <div className="col-12 col-sm-6 col-md-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/admin/add-profile")}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addContactModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addContactModalTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header d-flex align-items-center">
                    <h5 className="modal-title">Contact</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <form id="addContactModalTitle">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3 contact-name">
                                <input
                                  type="text"
                                  id="c-name"
                                  className="form-control"
                                  placeholder="Name"
                                />
                                <span className="validation-text text-danger"></span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3 contact-email">
                                <input
                                  type="text"
                                  id="c-email"
                                  className="form-control"
                                  placeholder="Email"
                                />
                                <span className="validation-text text-danger"></span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3 contact-occupation">
                                <input
                                  type="text"
                                  id="c-occupation"
                                  className="form-control"
                                  placeholder="Occupation"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3 contact-phone">
                                <input
                                  type="text"
                                  id="c-phone"
                                  className="form-control"
                                  placeholder="Phone"
                                />
                                <span className="validation-text text-danger"></span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3 contact-location">
                                <input
                                  type="text"
                                  id="c-location"
                                  className="form-control"
                                  placeholder="Location"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="btn-add"
                      className="btn btn-success rounded-pill px-4"
                    >
                      Add
                    </button>
                    <button
                      id="btn-edit"
                      className="btn btn-success rounded-pill px-4"
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-danger rounded-pill px-4"
                      data-bs-dismiss="modal"
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-body">
              <div className="table-responsive">
                <table className="table search-table align-middle text-nowrap">
                  <thead className="header-item">
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>IsLive</th>
                      <th>Visibility</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profileList &&
                      profileList
                        ?.filter((obj) => obj.UserName?.includes(searchText))
                        .map((item, i) => {
                          return (
                            <tr key={i} className="search-items">
                              <td>
                                <a
                                // onClick={() =>
                                //   navigate("/admin/edit-profile")
                                // }
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={
                                        item && item?.Profile_Pic
                                          ? Config.baseURL +
                                          "/" +
                                          item?.Profile_Pic
                                          : user
                                      }
                                      className="me-3 user-profile-img rounded-circle"
                                      width="35"
                                      height="35"
                                      alt=""
                                    />
                                    <div className="ms-3">
                                      <div className="user-meta-info">
                                        <h6 className="user-name mb-0">
                                          {item?.UserName}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </td>
                              <td>
                                {item?.Status === 1 ? (
                                  <span className="mb-1 badge bg-success">
                                    Online
                                  </span>
                                ) : (
                                  <span className="mb-1 badge bg-danger">
                                    Offline
                                  </span>
                                )}
                              </td>
                              <td>
                                {item?.IsLive === true ? (
                                  <span className="mb-1 badge bg-primary">
                                    Live
                                  </span>
                                ) : (
                                  <span className="mb-1 badge bg-danger">
                                    UnLive
                                  </span>
                                )}
                              </td>

                              {/* <td>
                                {item?.IsActive === true ? (
                                  <span className="mb-1 badge bg-primary">
                                    On
                                  </span>
                                ) : (
                                  <span className="mb-1 badge bg-danger">
                                    Off
                                  </span>
                                )}
                              </td> */}
                              <td>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckIsActive"
                                    name="IsActive"
                                    defaultChecked={item.IsActive}
                                    // checked={item?.IsActive}
                                    onChange={(e) => {
                                      handleStatusChange(e, item);
                                    }}
                                  />
                                </div>
                              </td>


                              {/* <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckChecked"
                                      checked={
                                        item?.IsActive === 1 ? true : false
                                      }
                                    />
                                  </div>
                                </td> */}

                              <td>
                                <div className="action-btn">
                                  <a
                                    onClick={() =>
                                      navigate("/admin/edit-profile", {
                                        state: { item },
                                      })
                                    }
                                    className="text-dark ms-2 edit"
                                  >
                                    <i
                                      className="fa-solid fa-pen-to-square"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                    <i className="ti ti-pencil fs-5"></i>
                                  </a>
                                  <a
                                    onClick={() => handleDelete(item?._id)}
                                    className="text-danger ms-2 edit"
                                  >
                                    <i
                                      className="fa-solid fa-trash-can"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                    <i className="ti ti-trash fs-5"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}

                    {profileList?.filter((obj) =>
                      obj.UserName?.includes(searchText)
                    )?.length === 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            margin: "0px 0px 0px 400px",
                          }}
                        >
                          No Records Found
                        </div>
                      )}
                    {/* <tr className="search-items">
                                            <td>
                                                <a onClick={() => navigate('/admin/edit-profile')} >
                                                    <div className="d-flex align-items-center">
                                                        <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                        <div className="ms-3">
                                                            <div className="user-meta-info">
                                                                <h6 className="user-name mb-0">Emma Adams</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-primary">Live</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr className="search-items">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                    <div className="ms-3">
                                                        <div className="user-meta-info">
                                                            <h6 className="user-name mb-0">Olivia Allen</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-success" >Online</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr className="search-items">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                    <div className="ms-3">
                                                        <div className="user-meta-info">
                                                            <h6 className="user-name mb-0">Jeni Kendall</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-danger">Offline</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr className="search-items">
                                            <td>
                                                <a onClick={() => navigate('/admin/edit-profile')} >
                                                    <div className="d-flex align-items-center">
                                                        <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                        <div className="ms-3">
                                                            <div className="user-meta-info">
                                                                <h6 className="user-name mb-0">Emma Adams</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-primary">Live</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="search-items">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                    <div className="ms-3">
                                                        <div className="user-meta-info">
                                                            <h6 className="user-name mb-0">Olivia Allen</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-success" >Online</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr className="search-items">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                    <div className="ms-3">
                                                        <div className="user-meta-info">
                                                            <h6 className="user-name mb-0">Jeni Kendall</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-danger">Offline</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="search-items">
                                            <td>
                                                <a onClick={() => navigate('/admin/edit-profile')} >
                                                    <div className="d-flex align-items-center">
                                                        <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                        <div className="ms-3">
                                                            <div className="user-meta-info">
                                                                <h6 className="user-name mb-0">Emma Adams</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-primary">Live</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="search-items">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                    <div className="ms-3">
                                                        <div className="user-meta-info">
                                                            <h6 className="user-name mb-0">Olivia Allen</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-success" >Online</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr className="search-items">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={user} alt="avatar" className="rounded-circle" width="35" />
                                                    <div className="ms-3">
                                                        <div className="user-meta-info">
                                                            <h6 className="user-name mb-0">Jeni Kendall</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="mb-1 badge bg-danger">Offline</span>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-dark ms-2 edit">
                                                        <i className="ti ti-pencil fs-5"></i>
                                                    </a>
                                                    <a onClick={() => navigate('/admin/edit-profile')} className="text-danger ms-2 edit">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfilePage;

import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "src/assets/images/logos/dark-logo.svg";
import PreLoader from "src/components/Loader";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { setApiMessage } from "src/helpers/commonFunctions";
import { identifiers } from "src/helpers/constants/identifier";
import { URLS } from "src/helpers/constants/urls";
import { saveToStorage } from "src/helpers/context";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [userName, setUserName] = useState("");

  const handleForgetPass = async () => {
    try {
      setLoader(true);
      let sendData = {
        email: userName,
      };
      const { status, data } = await axiosInstance.post(
        "FORGOT-PASS-URL",
        sendData
      );

      if (status === 200) {
        if (!data?.flags) {
          //   saveToStorage(identifiers?.authData, data);
          setApiMessage("success", data?.message);
          navigate("/admin/forgot-password-confirm", {
            state: { email: userName },
          });
        } else {
          setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setLoader(false);
    }
  };
  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        style={loader ? { opacity: "0.3" } : {}}
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center w-100">
            <div className="row justify-content-center w-100">
              <div className="col-md-8 col-lg-6 col-xxl-3">
                <div className="card mb-0">
                  <div className="card-body">
                    <a
                      href="/"
                      className="text-nowrap logo-img text-center d-block py-3 w-100"
                    >
                      <img src={logo} width="180" alt="" />
                    </a>
                    <p className="text-center">Welcome to Admin Panel</p>
                    {/* <form> */}
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                    <div className="mb-4" style={{ textAlign: "end" }}>
                      <a href="/admin/sign-in" className="form-label">
                        Sign In
                      </a>
                    </div>

                    <button
                      // href='/dashboard'
                      className="btn btn-primary w-100 py-8 fs-4 rounded-2"
                      onClick={() => handleForgetPass()}
                    >
                      Send Request
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgetPasswordPage;

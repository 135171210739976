import React from 'react';
import { Navigate } from 'react-router-dom';

// LAYOUT
import AuthLayout from 'src/layouts/AuthLayout';
import MainLayout from 'src/layouts/MainLayout';

// AUTHENTICATION
import LoginView from 'src/views/pages/auth/login';

import DashboardPage from './views/pages/application/Dashboard';
import DashboardView from './views/pages/auth/Dashboard';
import ChatView from './views/pages/auth/chat';
import ProfileView from './views/pages/auth/profile';
import EditProfileView from './views/pages/auth/editProfile';
import UserListView from './views/pages/auth/userList';
import StickersView from './views/pages/auth/stickers';
import NotificationView from './views/pages/auth/notification';
import ChatSuggestionsView from './views/pages/auth/chatSuggestions';
import SettingsView from './views/pages/auth/settings';
import ForgetPasswordView from './views/pages/auth/forgetPassword';
import ForgetPasswordConfirmView from './views/pages/auth/forgotPasswordConfirm';

// PUBLIC ROUTES
export const publicRoute = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { path: '/', element: <Navigate to='/admin/sign-in' /> },
      { path: '/admin/sign-in', element: <LoginView /> },
      { path: '/admin/forgot-password', element: <ForgetPasswordView /> },
      { path: '*', element: <Navigate to='/admin/sign-in' /> },
    ],
  },
];

// USER ROUTES
export const adminRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Navigate to='/admin/dashboard' /> },
      { path: '/admin/dashboard', element: <DashboardView /> },
      { path: '/admin/chat', element: <ChatView /> },
      { path: '/admin/profile', element: <ProfileView /> },
      { path: '/admin/edit-profile', element: <EditProfileView /> },
      { path: '/admin/add-profile', element: <EditProfileView /> },
      { path: '/admin/user-list', element: <UserListView /> },
      { path: '/admin/stickers', element: <StickersView /> },
      { path: '/admin/notification', element: <NotificationView /> },
      { path: '/admin/chat-suggestions', element: <ChatSuggestionsView /> },
      { path: '/admin/settings', element: <SettingsView /> },
      { path: '/admin/forgot-password-confirm', element: <ForgetPasswordConfirmView /> },
      { path: '*', element: <Navigate to='/admin/dashboard' /> },
    ],
  },
];

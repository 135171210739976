import React from 'react';

import Page from 'src/components/Page';
import ProfilePage from 'src/views/component/auth/Profile';

const ProfileView = () => {
  return (
    <Page title='Sign In'>
      <ProfilePage />
    </Page>
  );
};

export default ProfileView;

import React from 'react';
import Page from 'src/components/Page';
import ChatSuggestionsPage from 'src/views/component/auth/ChatSuggestions';

const ChatSuggestionsView = () => {
  return (
    <Page title='Sign In'>
      <ChatSuggestionsPage />
    </Page>
  );
};

export default ChatSuggestionsView;

import React from 'react';

import Page from 'src/components/Page';
import UserListPage from 'src/views/component/auth/UserList';

const UserListView = () => {
  return (
    <Page title='Sign In'>
      <UserListPage />
    </Page>
  );
};

export default UserListView;

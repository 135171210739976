import { AUTH_USER, LOGIN_USER } from '../constants';

export default function reduxData(state = [], action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authData: action.data };
    case LOGIN_USER:
      return { ...state, userData: action.data };
    default:
      return { state: [] };
  }
}

export const URLS = {
  SIGN_IN: '/adminLogin',
  GET_PROFILE:'/getFakeProfile',
  DELETE_PROFILE:"/DeleteFakeProfile",
  DELETE_PROFILE:"/DeleteFakeProfile",
  EDIT_FAKE_PROFILE : '/EditFakeProfile',
  UPDATE_PROFILE_IMAGE:'/Addprofile',
  UPDATE_BG_IMAGE:'/AddBGImage',
  GALLERY_IMAGE: '/Addgallery',
  STORY_IMAGE : '/Addstory',
  VIDEO_FOR_VIDEO_CALL : '/VideoCall',
  VIDEO_FOR_LIVE_STREAM : '/VidioStrem',
  GET_DASHBOARD : '/dashboard',
  ADDF_FAKE_PROFILE : '/AddFakeProfile',
  GET_STICKERT : '/listOfStricker',
  DELETE_STICKER :'/DeleteSticker',
  GET_USER :'/getUser',
  GET_LIST_OF_APP : '/listOfApp',
  APP_INSERT : '/appInsert',
  DELETE_APPLICATION:'/DeleteApplication',
  ADD_STICKER: "/Stickers",
  GET_LIST_CHAT_SUGGESTION : '/listOfChatSuggation',
  DELETE_CHAT_SUGGESTION:'/DeleteChatSuggation',
  ADD_CHAT_SUGGESTION : '/AddChatSuggation',
  EDIT_USER_LIST_PROFILE : '/EditUserProfile',
  CHAT_IMAGE : "/ChatUploadImage",
  UPDATE_ADMIN_PASSWORD : "/UpdateAdminPWD"
};
